import React from "react";

const Pagination = ({
  postsPerPage,
  totalArticles,
  paginate,
  firstPage,
  setFirstPage,
  currentPage,
  showall,
  showallSelect,
  isPro,
  search_type,
}) => {
  const pageNumbers = [];
  const lastPage = Math.ceil(totalArticles / postsPerPage);
  // var max_pages = totalArticles <= 20 ? lastPage : firstPage + 11;
  var max_pages = totalArticles <= postsPerPage ? lastPage : firstPage + 11;

  for (let i = firstPage; i <= max_pages; i++) {
    if (i <= lastPage) pageNumbers.push(i);
  }

  return (
    <nav aria-label="...">
      {totalArticles > 20 ? (
        <ul className="pagination">
          {firstPage > 1 ? (
            <li key={lastPage - 1} className="page-item">
              <span
                onClick={(e) => {
                  paginate(e, firstPage - 12);
                  setFirstPage(firstPage - 12);
                }}
                className="page-link"
              >
                Previous
              </span>
            </li>
          ) : (
            ""
          )}
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={
                number == currentPage ? "page-item active" : "page-item"
              }
            >
              <a
                className="page-link"
                href="#!"
                onClick={(e) => paginate(e, number)}
              >
                {number}{" "}
                {number == currentPage && (
                  <span className="sr-only">(current)</span>
                )}
              </a>
            </li>
          ))}
          {lastPage > 12 ? (
            <li key={lastPage} className="page-item">
              <a
                onClick={(e) => paginate(e, lastPage)}
                href="!#"
                className="page-link"
              >
                .. {lastPage}
              </a>
            </li>
          ) : (
            ""
          )}
          {lastPage > 12 && max_pages < lastPage ? (
            <li key={lastPage + 1} className="page-item">
              <a
                onClick={(e) => {
                  paginate(e, max_pages + 1);
                  setFirstPage(max_pages + 1);
                }}
                href="!#"
                className="page-link"
              >
                Next
              </a>
            </li>
          ) : (
            ""
          )}
        </ul>
      ) : (
        ""
      )}
      {isPro && search_type === "standard" && (
        <ul style={{ listStyleType: "none", padding: "0" }}>
          <li>
            <div className="result--taxonomies--pub">
              <a
                onClick={(e) => {
                  let val = "true";
                  if (
                    showall === "true" ||
                    showall === "1" ||
                    showall === true ||
                    showall === 0
                  ) {
                    val = "false";
                  } else if (
                    showall === false ||
                    showall === "false" ||
                    showall === "" ||
                    showall === "0" ||
                    showall === 0 ||
                    typeof showall === "undefined"
                  ) {
                    val = "true";
                  }
                  showallSelect(e, val);
                }}
                href="!#"
                // className="page-link"
              >
                {showall === "true" ||
                showall === "1" ||
                showall === true ||
                showall === 0
                  ? "You are seeing all results, including those with low relevance to the search, click here to remove these"
                  : "Some results have been removed due to low relevance to the search, click here to show all"}
              </a>
              <br></br>
            </div>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Pagination;