import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import axios from "axios";
import _ from "underscore";
import E from "react-script";

const SynonymsMdl = ({
  getParamsValues,
  criteria,
  show,
  setShowSynonymsModal,
}) => {
  const [synonymsfound, setSynonymsFound] = useState([]);
  const [reporterEmail, setreporterEmail] = useState("");
  const [synonymsSuggestion, setSynonymsSuggestion] = useState("");

  let foundItems = [];
  const fetchSynonyms = async (keywords) => {
    let synonyms_API =
      "https://yxvv1rc7ye.execute-api.eu-west-2.amazonaws.com/dev/search?searchTerm=$SEARCH_TERM".replace(
        "$SEARCH_TERM",
        keywords
      );

    axios
      .get(synonyms_API)

      .then(async (res) => {
        let data = null;
        if (res.status === 200) {
          if (!_.isUndefined(res.data)) {
            if (
              !_.isUndefined(res.data.matches) &&
              !_.isEmpty(res.data.matches)
            ) {
              setSynonymsFound(res.data.matches);
              // foundItems.push(synonymsfound);
              res.data.matches.forEach((obj) => {
                for (const key in obj) {
                  // console.log(`Key: ${key}`);
                  // console.log(`Value: ${obj[key]}`);
                  foundItems.push(obj[key]);
                }
              });
              foundItems = [...new Set(foundItems)];

              setSynonymsFound(foundItems);
            }
          }
        }
        if (_.isEmpty(foundItems)) setSynonymsFound(["None"]);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };
  const sendsynonymSuggestions = async (e) => {
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/User/synonyms"
          : "/User/synonyms",
        {
          reporterEmail: reporterEmail,
          synonyms: synonymsSuggestion,
          search: criteria.criteria,
        }
        // { withCredentials: true }
      )

      .then(async (res) => {
        if (res.status === 200) {
        }
      })
      .catch((error) => {
        return null;
      });
    e.preventDefault();
    setShowSynonymsModal(false);
  };
  useEffect(() => {
    if (!_.isUndefined(criteria.criteria)) {
      fetchSynonyms(criteria.criteria);
    }
  }, [criteria]);
  return (
    <Modal size="lg" show={show} onHide={() => setShowSynonymsModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Synonyms on Trip</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your search terms:{" "}
          <span className="bold text-navy">{getParamsValues()}</span>
        </p>
        Included synonyms:{" "}
        {synonymsfound.map((suggestion, idx) => (
          <li key={idx}>
            <span className="bold text-navy" idx={idx}>
              {!_.isUndefined(suggestion) ? suggestion : ""}{" "}
            </span>
          </li>
        ))}
        <br />
        <br />
        <Form>
          <Form.Group>
            <Form.Label>
              <i className="fa fa-info-circle-o"></i>
              Comment on Synonyms
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="synonyms suggestion"
              onChange={(e) => setSynonymsSuggestion(e.target.value)}
            />
          </Form.Group>
          <br></br>
          <Form.Group>
            <Form.Control
              type="email"
              placeholder="Enter your email (optional)"
              onChange={(e) => setreporterEmail(e.target.value)}
            />
          </Form.Group>
          <br></br>
          <Button
            variant="primary"
            onClick={(e) => {
              sendsynonymSuggestions(e);
            }}
          >
            Submit
          </Button>{" "}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SynonymsMdl;
