import React from "react";

const Footer = () => {
  return (
    <div className="footer mt-0">
      <div className="container">
        <div className="row footer--row-one">
          <div className="col footer--brand">
            <a href="/">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 165.17 104.41"
              >
                <g>
                  <path
                    d="M59.36,9.66V6.2Q59.36.62,56,.62H3.34Q0,.62,0,6.2V9.66q0,5.56,3.34,5.57H21.05v61.9H38.31V15.23H56q3.34,0,3.34-5.57M78.13,21.79q-7.47,0-12.14,8.48a17,17,0,0,1-.39-1.79,17.79,17.79,0,0,0-.5-2.06c-.15-.41-.38-.93-.67-1.56a3.23,3.23,0,0,0-1.17-1.4A6.56,6.56,0,0,0,60.31,23a38.78,38.78,0,0,0-6.74.89q-4.51.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71V77.13H66.88V41.87c2.23-3.72,5.19-5.58,8.91-5.58a11.35,11.35,0,0,1,2.89.45,11.28,11.28,0,0,0,2.34.44c1,0,1.79-1.17,2.45-3.51a22.09,22.09,0,0,0,1-5.86,6.75,6.75,0,0,0-1.4-4.18c-.93-1.22-2.58-1.84-5-1.84m81.25,8q-5.79-8-15-8a17.23,17.23,0,0,0-9,2.34,15.67,15.67,0,0,0-6,6,16.76,16.76,0,0,1-.39-1.68,19.62,19.62,0,0,0-.5-2,14.55,14.55,0,0,0-.67-1.62,3.23,3.23,0,0,0-1.17-1.4,6.53,6.53,0,0,0-3-.44,38.78,38.78,0,0,0-6.74.89q-4.52.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71V99.49h16.48v-21a18.85,18.85,0,0,0,11.36,3.46q11.13,0,17.32-8.09t6.18-22.08q0-14-5.79-22M138.11,68.85a15.68,15.68,0,0,1-7.8-2.12V41.87q3.78-6.59,9.13-6.58,9,0,9,16.78T138.11,68.85M99.08,0H96c-4.46,0-6.68,1.19-6.68,3.57v8.11h16.48V3.57Q105.76,0,99.08,0m0,22.85H96c-4.46,0-6.68,1.19-6.68,3.57V77.13h16.48V26.42q0-3.57-6.68-3.57"
                    fill="#a8a8a8"
                    fillRule="evenodd"
                  ></path>
                  <path
                    d="M37.33,77.53v.58Q37.33,82,31,82H27.26q-6.36,0-6.36-3.9v-.58Z"
                    fill="#63c608"
                    fillRule="evenodd"
                  ></path>
                  <path
                    d="M67.19,77.53v.58q0,3.9-6.66,3.9h-3.1q-6.66,0-6.66-3.9v-.58Z"
                    fill="#0e6cbb"
                    fillRule="evenodd"
                  ></path>
                  <path
                    d="M106,77.53v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                    fill="#00a89d"
                    fillRule="evenodd"
                  ></path>
                  <path
                    d="M130.7,100.06v.9q0,3.45-6.55,3.45h-3.06q-6.56,0-6.55-3.45v-.9Z"
                    fill="#eec82f"
                    fillRule="evenodd"
                  ></path>
                  <path
                    d="M106,11.83v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                    fill="#ba390d"
                    fillRule="evenodd"
                  ></path>
                </g>
              </svg>
            </a>
          </div>

          <div className="col footer--main-nav">
            <ul>
              <li>
                <a href="/" title="Home">
                  Home
                </a>
              </li>
              <li>
                <a href="/About" title="About">
                  About
                </a>
              </li>
              <li>
                <a href="/Howtouse" title="How to use">
                  How To Use
                </a>
              </li>
              <li>
                <a href="/Contact" title="Contact us">
                  Contact us
                </a>
              </li>
              <li>
                <a href="/view/evidence" title="Contact us">
                  Trip Evidence
                </a>
              </li>
              <li>
                <a href="/Popularsearches/" title="Contact us">
                  Popular Searches
                </a>
              </li>
              <li>
                <a
                  href="https://blog.tripdatabase.com/"
                  title="Blog"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li>
                <a href="/Why" title="Get Pro">
                  Get Trip Pro
                </a>
              </li>
            </ul>
          </div>

          <div className="col footer--follow-us">
            <p>Follow us</p>
            <ul>
              <li>
                <a
                  href="https://twitter.com/tripdatabase"
                  title="Follow us on Twitter"
                  target="_blank"
                >
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 88.96 88.96"
                  >
                    <title>inline-icon-twitter</title>
                    <path
                      d="M78.3,29.79c0-.75,0-1.51,0-2.26A35.67,35.67,0,0,0,87,18.44,34.78,34.78,0,0,1,76.93,21.2a17.64,17.64,0,0,0,7.71-9.71A35.2,35.2,0,0,1,73.5,15.76a17.55,17.55,0,0,0-30.36,12,17.73,17.73,0,0,0,.45,4A49.92,49.92,0,0,1,7.41,13.43a17.55,17.55,0,0,0,5.44,23.43,17.54,17.54,0,0,1-8-2.2v.22A17.56,17.56,0,0,0,19,52.09a17.52,17.52,0,0,1-4.62.62,17.92,17.92,0,0,1-3.3-.32,17.57,17.57,0,0,0,16.4,12.19A35.23,35.23,0,0,1,5.64,72.1a36.26,36.26,0,0,1-4.19-.25,49.71,49.71,0,0,0,26.91,7.89C60.65,79.74,78.3,53,78.3,29.79Z"
                      fill="#4a4a4a"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/tripdatabase"
                  title="Our Facebook page"
                  target="_blank"
                >
                  <svg
                    id="ed8a37b8-c8d2-4b4e-8a12-0f1a38b0582f"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 88.96 88.96"
                  >
                    <title>inline-icon-facebook</title>
                    <path
                      d="M34.58,21.84V32.22H24.67V44.48h9.91V81.26h16.5V44.48h12l1.19-12.26H51.08V27.11c0-2.93.64-4.09,3.68-4.09h9.53V7.7H51.72C39.85,7.7,34.58,12.55,34.58,21.84Z"
                      fill="#4a4a4a"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCK1DV5A9DzvyXEL1BsmAyjg"
                  target="_blank"
                  title="Our Youtube channel"
                >
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 121.48 85.04"
                  >
                    <path
                      d="M119,13.28A15.23,15.23,0,0,0,108.21,2.54C98.73,0,60.74,0,60.74,0s-38,0-47.46,2.54A15.21,15.21,0,0,0,2.54,13.28C0,22.75,0,42.52,0,42.52S0,62.29,2.54,71.76A15.21,15.21,0,0,0,13.28,82.5C22.75,85,60.74,85,60.74,85s38,0,47.47-2.54A15.23,15.23,0,0,0,119,71.76c2.53-9.47,2.53-29.24,2.53-29.24S121.48,22.75,119,13.28ZM48.59,60.74V24.3L80.16,42.52Z"
                      fill="#4a4a4a"
                    ></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <hr></hr>

        <div className="row footer--row-two">
          <div className="col-md footer--sub-nav">
            <ul>
              <li>
                <a href="/Terms" title="Terms of use">
                  Terms of use
                </a>
              </li>
              <li>
                <a href="/Privacy" title="Privacy">
                  Privacy
                </a>
              </li>
              <li>
                <a href="/Accessibility" title="Accessibility">
                  Accessibility
                </a>
              </li>
              <li>
                <a href="/Acceptableuse" title="Acceptable usage">
                  Acceptable usage
                </a>
              </li>
              <li>
                <a href="/Cookies" title="Tripdatabase - Cookies Policy">
                  Cookies Policy
                </a>
              </li>
            </ul>
          </div>

          {/* <div className="col-md small-print">
            <p className="">
                  © {new Date().getFullYear()} Trip Database Ltd. company number
                  04316414. Trip is proud to be made in the UK.
                </p>
          </div> */}
        </div>
        <div className="row footer--row-two">
          <div className="col-md footer--sub-nav center">
            <ul>
              <li>
                <p className="">
                  © {new Date().getFullYear()} Trip Database Ltd. company number
                  04316414. Trip is proud to be made in the UK. Enhanced by{" "}
                  <a href="https://zooz-consulting.com/" target="_blank">
                    ZOOZ
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
