import React from "react";
import _, { indexOf } from "underscore";
import store from "store";
import { Spinner, Modal } from "react-bootstrap";

import ResultSuggestions from "./ResultSuggestions";
import SynonymsMdl from "./SynonymsMdl";
const ResultHeader = ({
  props,
  totalArticles,
  loading,
  keyword,
  sort,
  categories_pro,
  currentParams = null,
  setSurveyMdlShow,
  surveymdlshow,
  handleSurvey,
}) => {
  var category_pro = _.where(categories_pro, { category_id: 11 });
  var category_pro_count = !_.isUndefined(category_pro[0])
    ? category_pro[0].count
    : 0;

  const [showSynonymsModal, setShowSynonymsModal] = React.useState(false);

  function getParamsValues() {
    let str = "";
    if (!_.isUndefined(currentParams.search_type)) {
      switch (currentParams.search_type) {
        case "standard":
          str += !_.isUndefined(currentParams.criteria)
            ? currentParams.criteria
            : "";
          str += !_.isUndefined(currentParams.clinical)
            ? " " + currentParams.clinical
            : "";
          break;
        case "pico":
          str += !_.isUndefined(currentParams.criteria)
            ? currentParams.criteria
            : "";
          str +=
            !_.isUndefined(currentParams.intervention) &&
            currentParams.intervention
              ? ", " + currentParams.intervention
              : "";
          str +=
            !_.isUndefined(currentParams.comparison) && currentParams.comparison
              ? ", " + currentParams.comparison
              : "";
          str +=
            !_.isUndefined(currentParams.outcome) && currentParams.outcome
              ? ", " + currentParams.outcome
              : "";
          break;
        case "advanced":
          str += !_.isUndefined(currentParams.criteria)
            ? currentParams.criteria
            : "";
          str +=
            !_.isUndefined(currentParams.anywords) && currentParams.anywords
              ? ", " + currentParams.anywords
              : "";
          str +=
            !_.isUndefined(currentParams.exactphrase) &&
            currentParams.exactphrase
              ? ", " + currentParams.exactphrase
              : "";
          str +=
            !_.isUndefined(currentParams.excludingwords) &&
            currentParams.excludingwords
              ? ", " + currentParams.excludingwords
              : "";
          str +=
            !_.isUndefined(currentParams.advfrom) && currentParams.advfrom
              ? ", " + currentParams.advfrom
              : "";
          str +=
            !_.isUndefined(currentParams.advto) && currentParams.advto
              ? ", " + currentParams.advto
              : "";
          break;
        case "advanced2":
          str += !_.isUndefined(currentParams.criteria)
            ? currentParams.criteria
            : "";
          str +=
            !_.isUndefined(currentParams.advfrom) && currentParams.advfrom
              ? ", " + currentParams.advfrom
              : "";
          str +=
            !_.isUndefined(currentParams.advto) && currentParams.advto
              ? ", " + currentParams.advto
              : "";
          break;
      }
      if (!_.isUndefined(currentParams.from_date))
        str += currentParams.from_date
          ? " from_date:" + currentParams.from_date
          : "";
      if (!_.isUndefined(currentParams.to_date))
        str += currentParams.to_date ? " to_date:" + currentParams.to_date : "";
      return str;
    } else return "";
  }
  const getHistoryAdv = () => {
    var existingHistoryAdv = "";
    if (!_.isUndefined(store.get("advanced"))) {
      existingHistoryAdv = store.get("advanced");
    }
    if (totalArticles && !_.isEmpty(existingHistoryAdv)) {
      let current_record = existingHistoryAdv.pop();
      if (!_.isUndefined(current_record.total)) {
        current_record["total"] = current_record["total"];
      } else current_record["total"] = totalArticles;
      existingHistoryAdv.push(current_record);
      store.set("advanced", existingHistoryAdv);
    }
    return true;
  };

  getHistoryAdv();
  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   props.history.push({
  //     pathname: "/Top/" + str,
  //     search: "?criteria=" + str + "&search_type=standard",
  //     state: {
  //       criteria: str,
  //       search_type: "standard",
  //     },
  //   });
  // };
  return (
    <div className="col mt-0">
      <div className="search-term">
        <div className="search-term--header">
          <h1>{getParamsValues()}</h1>
          <button
            className="btn btn-hairline btn-sm"
            onClick={() => setShowSynonymsModal(true)}
          >
            + Synonyms
          </button>
          <SynonymsMdl
            getParamsValues={getParamsValues}
            criteria={currentParams}
            show={showSynonymsModal}
            setShowSynonymsModal={setShowSynonymsModal}
          ></SynonymsMdl>
        </div>
        <div className="search-term--results-numbers">
          {loading ? (
            <div className="pull-center">
              <Spinner animation="border" size="sm" variant="success" />{" "}
              Processing results..
            </div>
          ) : (
            <span>
              {totalArticles ? (
                <span className="bold">
                  {totalArticles.toLocaleString() + "   results"}
                </span>
              ) : (
                "Results not found"
              )}
            </span>
          )}
          {totalArticles ? (
            <span className="pro-label">
              Pro users have access to +{category_pro_count} Systematic Reviews
            </span>
          ) : (
            ""
          )}
        </div>
        {/* {surveymdlshow && (
          <div className="result-filter-bar alert-light">
            <i className="fa fa-info-circle"></i>&nbsp; You've used a basic
            search, which may not provide the most accurate results.&nbsp;
            <a
              className="alert-link"
              onClick={(e) => {
                handleSurvey(e);
              }}
              href="https://forms.gle/jn8jAMs1A6GugZaVA"
              target="_blank"
            >
              Click here to participate in a quick survey and help us improve
              the site for you.
            </a>
          </div>
        )} */}
        <ResultSuggestions
          keywords={keyword}
          props={props}
          qry={getParamsValues()}
          currentParams={currentParams}
          totalArticles={totalArticles ? totalArticles : -1}
        />
      </div>
    </div>
  );
};

export default ResultHeader;
