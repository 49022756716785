import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

// All CSS imports now should be here
// import "./App/components/assets/css/Styles.css";
// import "./App/components/assets/css/ie.css";

import 'bootstrap/dist/css/bootstrap.min.css'; // all bootstrap
import './App/components/assets/css/global.css'; // custom vars and styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import App from "./App";


render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
