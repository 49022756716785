import React, { useState } from "react";
import ErrorMdl from "../components/errorMdl";
import PriceListMdl from "../components/personal/PriceList";
import WhyComponent from "../components/WhyComponent";
import { Helmet } from "react-helmet";


const Why = (props) => {
  const [errorshow, setErrorShow] = useState(false);
  const [hasError] = useState(false);
  const [show, setShow] = useState(false);

  document.body.classList.remove("homepage");
  const handleModal = (e) => {
    setShow(true);
    e.preventDefault();
  };
  return (
    <div>
      <Helmet>
        <title>Why use trip : Trip Medical Database</title>
        <meta
          name="description"
          content="Evidence-based answers for health professionals | Searching sources such as systematic reviews, clinical guidelines and RCTs"
        />
      </Helmet>
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <PriceListMdl setShow={setShow} show={show}></PriceListMdl>

      <WhyComponent
        isPro={props.isPro ? props.isPro : null}
        handleModal={handleModal}
      ></WhyComponent>
    </div>
  );
};

export default Why;
