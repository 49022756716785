import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import _ from "underscore";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import "../assets/css/Ckeditorcustom.css";

const EmailExportMdl = ({
  user,
  handleClose,
  handleEmailExport,
  show,
  emailexportto,
  emailexportmessage,
  setEmailExportMessage,
  setEmailExportTo,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="xl"
      enforceFocus={false}
      data-focus="false"
    >
      <Form width="100%" onSubmit={handleEmailExport}>
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-book"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="template.code">
            <Form.Label>
              <i className="fa fa-envelope"></i> From
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Sender Email"
              value={
                user && !_.isUndefined(user.user)
                  ? user.user.email
                  : "INVALID EMAIL"
              }
              //   onChange={(e) => setTemplateCode(e.target.value)}
              required
              disabled={true}
            />
          </Form.Group>
          <Form.Group controlId="template.to">
            <Form.Label>
              <i className="fa fa-envelope-o"></i> To:
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Email"
              value={emailexportto}
              onChange={(e) => setEmailExportTo(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="template.subject">
            <Form.Label>
              <i className="fa fa-info"></i> Subject
            </Form.Label>
            <Form.Control
              type="text"
              name="subject"
              placeholder="Subject"
              value={
                user && !_.isUndefined(user.email)
                  ? user.email.subject
                  : "INVALID SUBJECT"
              }
              //   onChange={(e) => setTemplateSubject(e.target.value)}
              disabled={true}
              required
            />
          </Form.Group>

          <Form.Group controlId="template.body">
            <Form.Label>
              <i className="fa fa-copy"></i> Body
            </Form.Label>
            <CKEditor
              style={{ height: "500px" }}
              editor={ClassicEditor}
              data={
                emailexportmessage
                // user && !_.isUndefined(user.email)
                //   ? user.email.message
                //   : "INVALID MESSAGE"
              }
              onInit={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                //  const data = editor.getData();
                setEmailExportMessage(editor.getData());
              }}
              disabled={true}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
          <Button variant="primary" onClick={handleEmailExport}>
            <i className="fa fa-envelope"></i> Send Email
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EmailExportMdl;
