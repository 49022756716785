import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {
  Nav,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Button,
} from "react-bootstrap";
import { checkTokensStorage } from "../../context/checkTokens";
import KeyModal from './KeyModal';

const ResultActions = ({
  isPro,
  sort,
  sortSelection,
  setSnippetShow,
  snippetshow,
  setExportShow,
  setProNotification,
  exportshow,
  isdesktop = true,
  categoryshow,
  setCategoryShow,
  rows,
  setResultRows,
}) => {
  var current_selection = "r",
    default_active = "r";
  var loginObj = checkTokensStorage();
  const [showKeyModal, setShowKeyModal] = useState(false);
  const options = [
    { value: "r", label: "TripRank" },
    { value: "t", label: "Relevance" },
    { value: "y", label: "Date" },
  ];

  if (sort) {
    current_selection =
      options.find((option) => option.value === sort)?.label || "TripRank";
    default_active = sort;
  }
  const handleKeyClick = (e) => {
    e.preventDefault();
    setShowKeyModal(true);
  };
  return isdesktop === true ? (
    <>
      <div className="results-actions">
        <div className="results-actions--left-group">
          <span>Order By</span>

          <Nav
            activeKey={sort || "r"}
            onSelect={(selectedKey) => sortSelection(selectedKey)}
          >
            <DropdownButton
              as={ButtonGroup}
              id="sort-nav-dropdown"
              variant="null"
              title={current_selection}
            >
              <Dropdown.Item eventKey="r">TripRank</Dropdown.Item>
              <Dropdown.Item eventKey="t">Relevance</Dropdown.Item>
              <Dropdown.Item eventKey="y">Date</Dropdown.Item>
              {/* <Dropdown.Item eventKey="v12m">Popularity</Dropdown.Item> */}
            </DropdownButton>
          </Nav>

          <div className="show-snippets-wrapper">
            <span>Show Snippets</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={snippetshow}
                value="1"
                onChange={(e) => setSnippetShow(!snippetshow)}
              />
              <span className="switch--slide-switch round"></span>
            </label>
          </div>
        </div>
        {loginObj.chkInstitution.institution_name && (
          <div>
            <i className="fa fa-university"></i>{" "}
            {loginObj.chkInstitution.institution_name}
            {/* <Link to="/Logout">
              <i className="fa fa-on-off"></i> Logout?
            </Link> */}
          </div>
        )}{" "}
        <div className="results-actions--right-group">
          <span>Show Results:</span>
          <Nav
            activeKey="20"
            onSelect={(selectedKey) => setResultRows(selectedKey)}
          >
            <DropdownButton
              as={ButtonGroup}
              id="sort-nav-dropdown 2"
              variant="null"
              title={rows}
            >
              <Dropdown.Item eventKey="20">20</Dropdown.Item>
              <Dropdown.Item eventKey="50">50</Dropdown.Item>
              <Dropdown.Item eventKey="100">100</Dropdown.Item>
            </DropdownButton>
          </Nav>
          <Link
            to="#"
            className="type-small"
            title="results page key"
            onClick={handleKeyClick}
          >
            Results page key
          </Link>

          <button
            type="button"
            className="btn dropdown-toggle"
            title="Export articles"
            onClick={(e) => {
              setExportShow(!exportshow);
              setProNotification(isPro ? false : true);
              e.preventDefault();
            }}
          >
            Export Articles <span className="sr-only">Toggle Dropdown</span>
          </button>
        </div>
      </div>

      <KeyModal show={showKeyModal} onHide={() => setShowKeyModal(false)} />
    </>
  ) : (
    <div className="results-actions">
      <div className="results-actions--main-options">
        <div className="filter-results-toggle">
          <Button
            className="navbar-btn js-sidebar-toggle navbar-toggler navbar-dark"
            type="button"
            title="Filter results"
            onClick={(e) => {
              setCategoryShow(!categoryshow);

              if (categoryshow) {
                document.body.classList.add("result-filters--is-visible");
              } else {
                document.body.classList.remove("result-filters--is-visible");
              }
            }}
            data-toggle
          >
            <span className="navbar-toggler-icon"></span>
            Filter Results
          </Button>
        </div>
        <div className="results-actions--order-by">
          <span>Order By</span>

          <Nav
            activeKey={sort || "r"}
            onSelect={(selectedKey) => sortSelection(selectedKey)}
          >
            <DropdownButton
              as={ButtonGroup}
              id="sort-nav-dropdown"
              variant="null"
              title={current_selection}
            >
              <Dropdown.Item eventKey="r">TripRank</Dropdown.Item>
              <Dropdown.Item eventKey="t">Relevance</Dropdown.Item>
              <Dropdown.Item eventKey="y">Date</Dropdown.Item>
            </DropdownButton>
          </Nav>
        </div>
        <div className="show-snippets-wrapper">
          <span>Show Snippets</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={snippetshow}
              value="1"
              onChange={(e) => setSnippetShow(!snippetshow)}
            />
            <span className="switch--slide-switch round"></span>
          </label>
        </div>
        <Link
          to="#"
          className="type-small"
          title="results page key"
          onClick={handleKeyClick}
        >
          Results page key
        </Link>
      </div>
      <div className="results-actions--more-options">
        <button
          type="button"
          className="btn dropdown-toggle"
          title="Export articles"
          onClick={(e) => {
            setExportShow(!exportshow);
            setProNotification(isPro ? false : true);
            e.preventDefault();
          }}
        >
          Export Articles <span className="sr-only">Toggle Dropdown</span>
        </button>
      </div>
    </div>
  );
};

export default ResultActions;