import React, { useEffect, useRef } from "react";
import _ from "underscore";
// import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
  setQueryStringValue,
  getQueryStringValue,
} from "../components/search/queryString";
const PageContentEvidence = ({
  currentPage,
  setCurrentPage,
  data,
  error,
  errorshow,
  setData,
  setErrors,
  setErrorShow,
  clsName,
  meta,
  setMeta,
}) => {
  const contentRef = useRef(null);
  async function fetchPage() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/EPages"
          : "/EPages",
        {
          params: {
            page: currentPage,
          },
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setData(res.data.html);
          setMeta(res.data.meta);
        } else {
          const error = new Error(res.error);
          setData("<p>Page not found!</p>");
          // setErrors(res.error);
          //setErrorShow(true);
        }
      })
      .catch((error) => {
        setData("<p>Page not found!</p>");

        // setErrors(error.message);
        // setErrorShow(true);
      });
  }

  useEffect(() => {
    document.body.classList.remove("homepage");
    fetchPage();
    const container = contentRef.current;

    const handleClick = (event) => {
      // Check if the clicked element or any of its parents is an <a> tag
      const target = event.target.closest("a");
      const clickedUrl = target.href;
      const url = new URL(clickedUrl);
      let card = clickedUrl.split("#");
      card = card && !_.isUndefined(card[1]) ? card[1] : "";
      if (target) {
        if (
          !window.location.hash &&
          clickedUrl.includes("evidence.tripdatabase.com")
        ) {
          event.preventDefault(); // Prevent default anchor click behavior
          // Get the pathname
          const path = url.pathname;

          setCurrentPage(path);
          setQueryStringValue("evidence_vol", path);
        }
        if (window.location.hash || card) {
          event.preventDefault();
          const element = document.getElementById(
            card
            // window.location.hash.substring(1)
          ); // Get the element by ID (remove #)
          if (element) {
            // Scroll to the element
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    };

    container.addEventListener("click", handleClick);

    // Cleanup the event listener on component unmount
    return () => {
      container.removeEventListener("click", handleClick);
    };
  }, [currentPage]);

  return (
    <div
      ref={contentRef}
      className={clsName}
      dangerouslySetInnerHTML={{ __html: data }}
    ></div>
  );
};

export default PageContentEvidence;
