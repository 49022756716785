import React, { Component } from "react";
import { Switch } from "react-router-dom";
// import "./App/components/assets/css/Styles.css";
// import './App/components/assets/css/global.css';
// import "./App/components/assets/css/ie.css";

import Routers from "./Routers";

class App extends Component {
  render() {
    const App = () => <Routers />;
    return (
      <Switch>
        <App />
      </Switch>
    );
  }
}

export default App;
