import React from "react";

export default () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 193.32 146.62"
  >
    <polygon
      points="96.66 0.24 65.03 48.73 128.29 48.73 96.66 0.24"
      fill="#dadada"
    />
    <polygon
      points="47.25 75.97 33.33 97.31 159.98 97.31 146.07 75.97 47.25 75.97"
      fill="#dadada"
    />
    <polygon
      points="1.32 146.38 192 146.38 177.76 124.56 15.55 124.56 1.32 146.38"
      fill="#dadada"
    />
    <polygon
      points="17.48 121.6 175.83 121.6 161.91 100.27 31.4 100.27 17.48 121.6"
      fill="#fa860e"
    />
    <polygon
      points="130.22 51.68 63.1 51.68 49.18 73.02 144.14 73.02 130.22 51.68"
      fill="#dadada"
    />
  </svg>
);
