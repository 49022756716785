import React, { useState } from "react";
// import { Card, Table, Row, Col } from "react-bootstrap";
// import AsyncSelect from "react-select/async";
import axios from "axios";

// import "../assets/css/Connected.css";

const _ = require("underscore");

function dp2(num) {
    return Number(num).toFixed(2);
}

function formatDate(d) {
    if (d===undefined || d===null) return '';
    return d.toString().split('-')[0];
}

function evidenceClassName(categoryId) {

    if (categoryId===null || categoryId===undefined)
        return "";

    switch( Number(categoryId) ) {
        case 1:
        case 11:
        case 16:
        case 18:
        case 10:
        case 9:
        case 4:
        case 34:
            return "evidence-dot--evidence-secondary";

        case 13:
            return "evidence-dot--evidence-key-primary";

        case 2:
            return "evidence-dot--evidence-clinical-q-and-a"

        case 14:
        case 27:
            return "evidence-dot--evidence-all-primary";

        default:
            return "evidence-dot evidence-dot--evidence-other";

    }
}

const ConnectedResults = ({ clicked, results, factors, setFactors, onSubmit, onClick, isPro, onShowHide }) => {

    const { concepts, documents, summary, metrics } = results || {};

    const onClickConcept = function (event, concept) {
        event.preventDefault();

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const criteria = urlParams.get('criteria')
        let addition = concept.display_name;
        if (addition.indexOf(' ') != -1) addition = '"' + addition + '"';
        const keywords = criteria + ' ' + addition;
        window.location = '/SearchResult?criteria=' + escape(keywords);
    }

    // only auto-show if 1 result ("Related")
    const [show, setShow] = useState(clicked?.length==1);

    const toggleShow = () => {
      const newShow=!show;
        if (newShow) {
            onSubmit();
        }
        setShow(newShow);
        onShowHide(newShow);
    }

    return (
      <div
        className={`trip-connected-wrapper slideInUp ${
          show ? "trip-connected-is-open" : null
        }`}
      >
        <div className="trip-connected">
          <div className="trip-connected--head">
            <div className="trip-connected--head--main">
              <div className="trip-connected-logo">
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 165.17 104.41"
                >
                  <g>
                    <path
                      d="M59.36,9.66V6.2Q59.36.62,56,.62H3.34Q0,.62,0,6.2V9.66q0,5.56,3.34,5.57H21.05v61.9H38.31V15.23H56q3.34,0,3.34-5.57M78.13,21.79q-7.47,0-12.14,8.48a17,17,0,0,1-.39-1.79,17.79,17.79,0,0,0-.5-2.06c-.15-.41-.38-.93-.67-1.56a3.23,3.23,0,0,0-1.17-1.4A6.56,6.56,0,0,0,60.31,23a38.78,38.78,0,0,0-6.74.89q-4.51.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71V77.13H66.88V41.87c2.23-3.72,5.19-5.58,8.91-5.58a11.35,11.35,0,0,1,2.89.45,11.28,11.28,0,0,0,2.34.44c1,0,1.79-1.17,2.45-3.51a22.09,22.09,0,0,0,1-5.86,6.75,6.75,0,0,0-1.4-4.18c-.93-1.22-2.58-1.84-5-1.84m81.25,8q-5.79-8-15-8a17.23,17.23,0,0,0-9,2.34,15.67,15.67,0,0,0-6,6,16.76,16.76,0,0,1-.39-1.68,19.62,19.62,0,0,0-.5-2,14.55,14.55,0,0,0-.67-1.62,3.23,3.23,0,0,0-1.17-1.4,6.53,6.53,0,0,0-3-.44,38.78,38.78,0,0,0-6.74.89q-4.52.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71V99.49h16.48v-21a18.85,18.85,0,0,0,11.36,3.46q11.13,0,17.32-8.09t6.18-22.08q0-14-5.79-22M138.11,68.85a15.68,15.68,0,0,1-7.8-2.12V41.87q3.78-6.59,9.13-6.58,9,0,9,16.78T138.11,68.85M99.08,0H96c-4.46,0-6.68,1.19-6.68,3.57v8.11h16.48V3.57Q105.76,0,99.08,0m0,22.85H96c-4.46,0-6.68,1.19-6.68,3.57V77.13h16.48V26.42q0-3.57-6.68-3.57"
                      fill="#533764"
                      fillRule="evenodd"
                    ></path>
                    <path
                      d="M37.33,77.53v.58Q37.33,82,31,82H27.26q-6.36,0-6.36-3.9v-.58Z"
                      fill="#63c608"
                      fillRule="evenodd"
                    ></path>
                    <path
                      d="M67.19,77.53v.58q0,3.9-6.66,3.9h-3.1q-6.66,0-6.66-3.9v-.58Z"
                      fill="#0e6cbb"
                      fillRule="evenodd"
                    ></path>
                    <path
                      d="M106,77.53v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                      fill="#00a89d"
                      fillRule="evenodd"
                    ></path>
                    <path
                      d="M130.7,100.06v.9q0,3.45-6.55,3.45h-3.06q-6.56,0-6.55-3.45v-.9Z"
                      fill="#eec82f"
                      fillRule="evenodd"
                    ></path>
                    <path
                      d="M106,11.83v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                      fill="#ba390d"
                      fillRule="evenodd"
                    ></path>
                  </g>
                </svg>
                Connected
              </div>
              <p>
                {results
                  ? summary
                  : `Discover results based on your ${clicked.length} click${
                      clicked.length == 1 ? "" : "s"
                    }`}
              </p>
            </div>
            <div className="trip-connected--head--ctas">
              <a
                className="btn btn-primary js-show-connected-results-toggle text-white"
                onClick={toggleShow}
              >
                {show ? "Hide" : "Show"} Results
              </a>
              <a
                href="https://blog.tripdatabase.com/2023/11/04/understanding-connected-articles/"
                target="_blank"
              >
                What is this?
              </a>
            </div>
          </div>
          <div className="trip-connected--body">
            {results ? (
              <table className="table table-sm table-responsive trip-connected--table">
                <tbody>
                  {documents && documents.length
                    ? documents.map((document, index) => (
                        <tr key={document.id}>
                          <td className="trip-connected-results--title">
                            <div className="trip-connected-results--title--wrapper">
                              <span
                                className={`evidence-dot ${evidenceClassName(
                                  document.categoryId
                                )}`}
                              ></span>
                              <a
                                href={document.url}
                                target="_blank"
                                onClick={() => onClick(document)}
                              >
                                {document.title}
                              </a>
                            </div>
                          </td>
                          <td className="trip-connected-results--year">
                            {formatDate(document.trip_date)}
                          </td>
                          <td className="trip-connected-results--institution">
                            {document.publication || ""}
                          </td>
                        </tr>
                      ))
                    : null}

                  {documents && !isPro && (
                    <>
                      <tr>
                        <td colSpan="5">
                          <a className="not-underline" href="/Why">
                            Many more results with a{" "}
                            <span className="pro-label">Pro</span> account...
                          </a>
                        </td>
                      </tr>
                      <tr style={{ filter: "blur(4px)" }}>
                        <td className="trip-connected-results--title">
                          <div className="trip-connected-results--title--wrapper">
                            <span className="evidence-dot evidence-dot--evidence-secondary"></span>
                            <a href="/Why" target="_blank">
                              OK, very clever, you can use debugging tools in
                              your browser to reveal this!
                            </a>
                          </div>
                        </td>
                        <td className="trip-connected-results--year">2000</td>
                        <td className="trip-connected-results--institution">
                          Abcradabra
                        </td>
                      </tr>
                      <tr style={{ filter: "blur(4px)" }}>
                        <td className="trip-connected-results--title">
                          <div className="trip-connected-results--title--wrapper">
                            <span className="evidence-dot evidence-dot--evidence-secondary"></span>
                            <a href="/Why" target="_blank">
                              If you upgraded to a Pro account, you wouldn't
                              need to waste your time messing with developer
                              tools
                            </a>
                          </div>
                        </td>
                        <td className="trip-connected-results--year">2000</td>
                        <td className="trip-connected-results--institution">
                          Rumpelstiltskin
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
      </div>
    );


}

export default ConnectedResults;